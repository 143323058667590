<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand text-primary" href="#"><strong>Menita - QR Carga Factura </strong></a>
</nav>


<main class="container">

  <!--SCANNER-->


  <div class="embed-responsive embed-responsive-16by9">
    <ngx-scanner-qrcode #scanner (event)="verifyInvoice($event)" class="embed-responsive-item"></ngx-scanner-qrcode>
    <div class="embed-responsive camera-pause-overlay" *ngIf="!scanner.isLoading && !scanner.isStart">
      <p>⏸ Cámara pausada</p>
    </div>
    <div class="embed-responsive-item camera-loader" *ngIf="scanner.isLoading">
      <p>📷 Iniciando cámara...</p>
    </div>
  </div>
  <!--SCANNER-->




  <!--SCANNER ACTIONS-->
  <div class="row align-items-center justify-content-between mt-3">
    <!--AVAILABLE CAMERAS-->
    <div class="col-12 col-sm-6 mt-3 mt-sm-0">
      <div class="form-group">
        <label for="cameraSelection">Cámara activa</label>
        <select class="form-control" id="cameraSelection" (change)="onSelectCamera($event.target.value)">
          <option *ngFor="let camera of cameras" [value]="camera.deviceId">
            {{camera.label}}
          </option>
        </select>
      </div>
    </div>
    <!--AVAILABLE CAMERAS-->
    <div class="col-12 col-sm-6 mt-3 mt-sm-0 text-center text-sm-right">
      <button type="button" class="btn btn-outline-success" (click)="reOpenCamera()">
        📷 Nueva factura
      </button>
    </div>
  </div>
  <!--SCANNER ACTIONS-->

  <!--FORM-->
  <form [formGroup]="form" (ngSubmit)="onSubmit($event)">
    <div class="card mt-5">
      <div class="card-header">
        Envío de factura

      </div>
      <div class="card-body">

        <!--INVOICE-->
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              Factura
            </span>
          </div>
          <input id="invoice" type="text" class="form-control" aria-label="Invoice" formControlName="invoice" disabled
            aria-describedby="basic-addon1" />

        </div>
        <span *ngIf="form.get('invoice').errors && form.get('invoice').errors.required" class="text-danger mt-1">
          La factura es requerida.
        </span>
        <!--INVOICE-->
        <!--FILE-->
        <div class="row align-items-center mt-3">
          <div class="col-12 col-md-6">
            <div class="input-group">
              <div class="custom-file">
                <input type="file" class="custom-file-input" id="invoiceImage" accept="image/*"
                  formControlName="invoiceImage" (change)="handleImageSelection($event)" />
                <label class="custom-file-label" for="invoiceImage">Añadir imagen</label>
              </div>
            </div>
            <span *ngIf="form.get('invoiceImage').errors && form.get('invoiceImage').errors.required"
              class="text-danger mt-1">
              La imagen es requerida.
            </span>
          </div>
          <div class="col-12 col-md-6">
            <img [src]="invoiceImage" alt="imagen factura" class="invoice-image">
          </div>
        </div>
        <!--FILE-->
        <button type="submit" class="btn btn-primary btn-block mt-5" [disabled]="uploading">
          <span *ngIf="uploading">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
                class="spinner_P7sC" />
            </svg>
            Enviando...
          </span>
          <span *ngIf="!uploading">
            Enviar factura
          </span>
        </button>

      </div>
    </div>
  </form>
  <!--FORM-->

</main>

<footer class="page-footer font-small bg-light footer">
  <div class="footer-copyright text-center py-3" style="color: #444444">
    © 2020 Copyright:
    <a href="#">menitalabs</a>
  </div>
</footer>