import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeConfig,
  ScannerQRCodeDevice,
  ScannerQRCodeResult,
} from 'ngx-scanner-qrcode';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { QrService } from '../services/qrService.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('scanner') scanner!: NgxScannerQrcodeComponent;
  public uploading: boolean = false;
  public verifying: boolean = false;
  public invoiceImage: string | ArrayBuffer;
  public cameras: ScannerQRCodeDevice[] = [];
 

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private qrService: QrService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      invoice: ['', Validators.required],
      invoiceImage: [null, Validators.required],
    });
  }

  ngAfterViewInit(): void {
    this.scanner.start();
    this.scanner.devices.subscribe((cameras) => {
      this.cameras = cameras.slice(0, 2);
    });
  }

  public reOpenCamera(): void {
    this.form.patchValue({ invoice: '', invoiceImage: null });
    this.form.reset();
    this.scanner.start();
  }

  public onSelectCamera(deviceId: string): void {
    this.scanner.playDevice(deviceId);
  }

  public handleImageSelection($event): void {
    const fileContent = $event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(fileContent);
    fileReader.onload = (_event) => {
      this.invoiceImage = fileReader.result;
    };
    this.form.patchValue({ invoiceImage: fileContent });
  }

  public onSubmit(event: Event): boolean {
    event.preventDefault();
    if (this.form.valid) {
      const invoice = this.form.get('invoice').value;
      const image = this.form.get('invoiceImage').value;
      const imageMimeType = image.type.split('/')[1];

      this.uploading = true;
      this.qrService
        .sendFactura(invoice, image, imageMimeType)
        .then((res) => this.onInvoiceUploadSuccess(res))
        .catch((error) => this.onInvoiceUploadError(error))
        .finally(() => (this.uploading = false));
    }
    return true;
  }

  public verifyInvoice(result: BehaviorSubject<ScannerQRCodeResult[]>): void {
    this.scanner.stop();
    const codeResult = result[0].value;
    const invoiceParts = codeResult.match(
      /id=(.*)&re=(.*)&rr=(.*)&tt=(.*)&fe=(.*)/
    );
    if (!invoiceParts) return this.handleVerifyError(null);

    this.verifying = true;
    const invoice = invoiceParts[1];
    this.qrService.checkQr(invoice).subscribe(
      (res: any) => this.handleSuccessVerify(res, invoice),
      (error) => this.handleVerifyError(error)
    );
  }

  private handleSuccessVerify(res: any, invoice: string): void {
    const UUID_NOT_FOUND = 'id-no-match';

    this.verifying = false;
    if (res.success) {
      this.form.patchValue({ invoice });
      return;
    }
    if (res.error === UUID_NOT_FOUND) {
      this.scanner.start();
      this.toastr.clear();
      this.toastr.error('Error al verificar ID  de factura.', 'Mensaje', {
        timeOut: 3000,
      });
    }
  }

  private handleVerifyError(error): void {
    this.verifying = false;
    this.toastr.clear();
    this.scanner.start();
    this.toastr.error('Error al verificar ID  de factura.', 'Mensaje', {
      timeOut: 3000,
    });
  }

  private onInvoiceUploadSuccess(res: any): void {
    this.invoiceImage = '';
    this.toastr.clear();
    this.toastr.success('Factura enviada correctamente.', 'Mensaje', {
      timeOut: 3000,
    });
    this.reOpenCamera();
  }

  private onInvoiceUploadError(error): void {
    this.toastr.clear();
    this.toastr.error(
      'Error al enviar factura, intenta nuevamente',
      'Mensaje',
      {
        timeOut: 3000,
      }
    );
  }
}
