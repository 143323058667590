import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class QrService {

  URL_BASE: string;

  constructor(private httpClient: HttpClient) {
    this.URL_BASE = environment.URL_BASE;
  }

  checkQr(UUID: String):Observable<any> {
    return this.httpClient.get(this.URL_BASE + '/qrcode/' + UUID);
  }

  sendFactura(UUID: string, file: File, type_img: string): Promise<any>{
    return new Promise((resolve, reject ) => {
      const formatData: FormData = this.dataFormData(UUID, file, type_img);
      const url = this.URL_BASE + '/qrcode';
          fetch(url, {
            method: 'POST', // or 'PUT'
            body: formatData, // data can be `string` or {object}!
          }).then(res => res.json())
            .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  dataFormData(UUID: string, file:File, type_img:string): FormData{
    const formatData = new FormData();
    formatData.append('id', UUID);
    formatData.append('factura_imagen', file);
    //formatData.append('type_img', type_img);
    return formatData;
  }

}

